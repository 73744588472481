import type { ResponseStoryInterface } from '~/types/content';
import StoryblokClient from 'storyblok-js-client';

const TOKEN = process.env.STORYBLOK_ACCESS_TOKEN;
const PER_PAGE = 100;

let InnerStoryblok: StoryblokClient;
const Storyblok = () => {
    if (!InnerStoryblok) {
        InnerStoryblok = new StoryblokClient({
            accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
            region: 'us',
        });
    }
    return InnerStoryblok;
};

/**
 * Fetch stories from Storyblok API recursively
 */
export default async function fetchStories(
    components: string[],
    version: 'draft' | 'published',
    cv: number,
    currentPage: number,
    allStories: ResponseStoryInterface[] = [],
    relations: string[] = [],
): Promise<ResponseStoryInterface[]> {
    if (!TOKEN) {
        throw new Error('STORYBLOK_ACCESS_TOKEN not set!');
    }

    let response;
    try {
        console.log(`[REQUEST]: Storyblok stories API page ${currentPage}`);
        response = await Storyblok().get('cdn/stories', {
            version,
            token: TOKEN,
            filter_query: {
                component: {
                    in: components.join(','),
                },
            },
            cv,
            per_page: PER_PAGE,
            page: currentPage,
            resolve_relations: relations,
            resolve_links: 'url',
        });
    } catch (error) {
        console.error('Failed to fetch data', error);
        return allStories;
    }

    const newStories = response.data.stories;
    if (!newStories.length) {
        return allStories;
    }

    return fetchStories(components, version, cv, currentPage + 1, allStories.concat(newStories), relations);
}
