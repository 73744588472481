
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Collapse',
    props: {
        showEsBrand: {
            type: Boolean,
            default: false,
            required: false,
        },
        heading: {
            type: String,
            required: true,
        },
    },
});
