interface enumConstants {
    [key: string]: string;
}

export const DATA_SETS: enumConstants = {
    SOLAR_COST: 'solarCost',
    SOLAR_COMPANIES: 'solarCompanies',
};

export const GEO_LEVELS: enumConstants = {
    CITY: 'city',
    COUNTY: 'county',
};
