/* eslint-disable no-param-reassign, no-console */

import type {
    ArticleDataRichContentBlokInterface,
    DataRichContentSubsetDataCostInterface,
} from '@energysage/storyblok-shared';
import { DATA_SETS } from './constants';

export const dataRichContentUniqueKey = (value: ArticleDataRichContentBlokInterface) =>
    `${value.state}_${value.dataset}`;

const getDataSubsetRichContentCost = (apiResponse: any): DataRichContentSubsetDataCostInterface => ({
    aboveAveragePrice5kw: apiResponse.localQuoteAnalysis.aboveAvgPrice,
    aboveAveragePriceAvgKw: apiResponse.localQuoteAnalysisAvgSystemSize.aboveAvgPrice,
    averagePrice5kw: apiResponse.localQuoteAnalysis.averagePrice,
    averagePriceAvgKw: apiResponse.localQuoteAnalysisAvgSystemSize.averagePrice,
    averageSystemSize: apiResponse.localQuoteAnalysisAvgSystemSize.averageSystemSize,
    belowAveragePrice5kw: apiResponse.localQuoteAnalysis.belowAvgPrice,
    belowAveragePriceAvgKw: apiResponse.localQuoteAnalysisAvgSystemSize.belowAvgPrice,
    costPerWatt: apiResponse.localQuoteAnalysis.costPerWatt,
    electricityOffsetPercentage: apiResponse.localQuoteAnalysisAvgSystemSize.percentNeedMet,
    lastUpdated: apiResponse.lastUpdated,
    numRegisteredProperties: apiResponse.numRegisteredProperties,
    paybackPeriodYears: apiResponse.localQuoteAnalysis.purYearsPayback,
    stateName: apiResponse.stateName,
    twentyYearSavings: apiResponse.localQuoteAnalysis.purLifeSavingsNet,
});

const datasetEndpointMap = {
    [DATA_SETS.SOLAR_COST]: {
        endpointDomain: process.env.BULK_LOCAL_SERVICE_DOMAIN,
        endpointPath: '/bulk-local/page/SolarCost/',
        getDataSubset: getDataSubsetRichContentCost,
    },
};

export default async (
    value: ArticleDataRichContentBlokInterface,
    requestCache: Record<string, any>,
    moduleDataCache: Record<string, any>,
    moduleData: Record<string, any>,
) => {
    const uniqueKey = dataRichContentUniqueKey(value);
    if (moduleDataCache[uniqueKey]) {
        moduleData[uniqueKey] = moduleDataCache[uniqueKey];
        console.log(`FOUND in moduleDataCache: ${uniqueKey} ${value.component}`);
    } else {
        console.log(`NOT FOUND in moduleDataCache: ${uniqueKey} ${value.component}`);
        const dataSetName: typeof DATA_SETS.SOLAR_COST = value.dataset;
        const { endpointDomain, endpointPath, getDataSubset } = datasetEndpointMap[dataSetName];
        const requestPath = `${endpointPath}?state=${value.state}`;
        let data: any = {};
        if (requestCache[requestPath]) {
            data = requestCache[requestPath];
            console.log(`FOUND in requestCache: ${requestPath}`);
        } else {
            console.log(`NOT FOUND in requestCache: ${requestPath}`);
            const resp = await fetch(`${endpointDomain}${requestPath}`);
            if (resp.status !== 200) {
                console.warn(`Received non-200 status ${resp.status} when fetching ${requestPath}`);
                moduleData[uniqueKey] = {};
                moduleDataCache[uniqueKey] = {};
                return;
            }
            data = await resp.json();
            requestCache[requestPath] = data;
        }
        const finalData: Record<string, any> = getDataSubset(data);

        moduleData[uniqueKey] = finalData;
        moduleDataCache[uniqueKey] = finalData;
    }
};
