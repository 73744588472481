/* eslint-disable no-param-reassign, no-console */
import type {
    ArticleDataGeoLinksApiDataInterface,
    ArticleDataGeoLinksBlokInterface,
} from '@energysage/storyblok-shared';
import { DATA_SETS, GEO_LEVELS } from './constants';

export const dataGeoLinksUniqueKey = (value: ArticleDataGeoLinksBlokInterface) => `${value.state}_${value.level}`;

export default async (
    value: ArticleDataGeoLinksBlokInterface,
    requestCache: Record<string, any>,
    moduleDataCache: Record<string, ArticleDataGeoLinksApiDataInterface>,
    moduleData: Record<string, ArticleDataGeoLinksApiDataInterface>,
) => {
    const uniqueKey = dataGeoLinksUniqueKey(value);
    if (moduleDataCache[uniqueKey]) {
        moduleData[uniqueKey] = moduleDataCache[uniqueKey];
        console.log(`FOUND in moduleDataCache: ${uniqueKey} ${value.component}`);
    } else {
        console.log(`NOT FOUND in moduleDataCache: ${uniqueKey} ${value.component}`);
        if (value.dataset === DATA_SETS.SOLAR_COMPANIES) {
            const requestPath = `/bulk-local/page/SolarCompany/?state=${value.state}`;
            let data: any = {};
            if (requestCache[requestPath]) {
                data = requestCache[requestPath];
                console.log(`FOUND in requestCache: ${requestPath}`);
            } else {
                console.log(`NOT FOUND in requestCache: ${requestPath}`);
                const solarCompanyUrl = `${process.env.BULK_LOCAL_SERVICE_DOMAIN}${requestPath}`;
                const resp = await fetch(solarCompanyUrl);
                if (resp.status !== 200) {
                    console.warn(`Received non-200 status ${resp.status} when fetching ${solarCompanyUrl}`);

                    // @ts-ignore
                    moduleData[uniqueKey] = {};

                    // @ts-ignore
                    moduleDataCache[uniqueKey] = {};
                    return;
                }
                data = await resp.json();
                requestCache[requestPath] = data;
            }
            const myModuleData: any = {
                state: data.stateName,
                stateAbbr: data.displayGeography,
            };
            if (value.level === GEO_LEVELS.COUNTY) {
                myModuleData.childUrlsList = data.childUrlsList;
            } else if (value.level === GEO_LEVELS.CITY) {
                const topCitiesUrl = `${process.env.BULK_LOCAL_SERVICE_DOMAIN}/bulk-local/${value.state}/cities`;
                const topCitiesResp = await fetch(topCitiesUrl);
                if (topCitiesResp.status !== 200) {
                    console.warn(`Received non-200 status ${topCitiesResp.status} when fetching ${topCitiesUrl}`);

                    // @ts-ignore
                    moduleData[uniqueKey] = {};

                    // @ts-ignore
                    moduleDataCache[uniqueKey] = {};
                    return;
                }
                const topCitiesData = await topCitiesResp.json();

                // @ts-ignore
                myModuleData.childUrlsList = topCitiesData.cities.map(({ displayGeography, locationPath }) => [
                    displayGeography,
                    `/local-data/solar-companies${locationPath}`,
                ]);
            }
            moduleData[uniqueKey] = myModuleData;
            moduleDataCache[uniqueKey] = myModuleData;
        }
    }
};
