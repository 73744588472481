import { findDataModulesInStoryFunc } from './find-data-modules-in-story';

import ArticleDataGeoLinks from './data-geo-links';
import DataRichContent from './data-rich-content';

const SUPPORTED_DATA_MODULES: Record<string, Function> = {
    ArticleDataGeoLinks,
    ArticleDataRichContent: DataRichContent,
    DataRichContent,
};

export const findDataModulesInStory = findDataModulesInStoryFunc(SUPPORTED_DATA_MODULES);
