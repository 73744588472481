/* eslint-disable no-console */
import axios from 'axios';
import dotenv from 'dotenv';
import 'isomorphic-fetch';
import type {
    ResponseStoryInterface,
    PayloadInterface,
    StoyblokResponseInterface,
    StoryblokDatasourceEntriesInterface,
} from '~/types/content';
import type { ArticlePage001 } from '~/types/articlePage';
import fetchStories from './fetch-stories';
import { findDataModulesInStory } from '../../utils/data-modules';

dotenv.config();

export interface Route {
    url: string;
    lastmod?: string;
    full_slug?: string;
    noIndex: boolean;
    payload: PayloadInterface;
}

const BASE_URL = 'https://api-us.storyblok.com/v2/cdn/stories';
const TOKEN = process.env.STORYBLOK_ACCESS_TOKEN;
const PER_PAGE = 100;

const determineIfStoryIsNoIndex = (story: ResponseStoryInterface) => {
    if (story?.content?.component && story?.content?.component === 'ArticlePage001') {
        const articlePage: ArticlePage001 = story.content;
        return !!articlePage.noIndex;
    }

    return false;
};

export async function getStoryData(fullSlugs: string[]): Promise<ResponseStoryInterface[] | undefined> {
    const cv = Math.floor(Date.now() / 1e3);
    const bySlugs = fullSlugs.join(',');
    const url = `${BASE_URL}?token=${TOKEN}&by_slugs=${bySlugs}&cv=${cv}`;

    try {
        const response = await axios.get<StoyblokResponseInterface>(url);
        return response.data.stories;
    } catch (error) {
        return [];
    }
}

const DATASOURCE_BASE_URL = 'https://api-us.storyblok.com/v2/cdn/datasource_entries';

async function fetchDatasourceEntry(datasourceName: string): Promise<Record<string, any>> {
    if (!TOKEN) {
        throw new Error('STORYBLOK_ACCESS_TOKEN not set!');
    }
    const params = new URLSearchParams({
        token: TOKEN,
        datasource: datasourceName,
    });
    const url = `${DATASOURCE_BASE_URL}?${params.toString()}`;
    try {
        console.log(`[REQUEST]: ${url}`);
        const { data } = await axios.get<StoryblokDatasourceEntriesInterface>(url);
        const datasourceEntries = data.datasource_entries;
        const datasourceObject = datasourceEntries.reduce((obj: Record<string, any>, item: any) => {
            // Assuming each item has a unique 'name' property that we can use to form the keys of the new object
            // eslint-disable-next-line no-param-reassign
            obj[item.name] = item.value;
            return obj;
        }, {});
        return datasourceObject;
    } catch (error) {
        console.error('Failed to fetch data', error);
        return {};
    }
}

export const withLeadingAndTrailingSlash = (rawUrl: string) => {
    let processedUrl = rawUrl;
    if (!processedUrl.startsWith('/')) {
        processedUrl = `/${processedUrl}`;
    }
    if (!processedUrl.endsWith('/')) {
        processedUrl = `${processedUrl}/`;
    }
    return processedUrl;
};

/**
 * Get all routes from Storyblok API for a given array of components
 * @param components The array of components to get all routes for
 * @returns An array of routes
 * @example
 * ```
 * const routes = await getRoutes(['ArticlePage001', 'ArticlePage101]);
 * ```
 */
export default async (
    components: string[],
    version: 'draft' | 'published' = 'published',
    relations: string[] = [],
): Promise<Route[]> => {
    const cv = Math.floor(Date.now() / 1e3);
    const allStories = await fetchStories(components, version, cv, 1, [], relations);
    const storiesByFullSlug = allStories.reduce(
        (obj, story) => ({
            ...obj,
            [withLeadingAndTrailingSlash(story.full_slug)]: story,
        }),
        {} as Record<string, ResponseStoryInterface>,
    );

    console.log('[FETCHING ROUTES]:', components.join(', '));

    const seoDataPayload = await fetchDatasourceEntry('seo-data');
    const globalArticleCopyPayload = await fetchDatasourceEntry('global-article-copy');

    // Cache mapping API request paths to responses, to avoid repeat requests overall
    const requestCache = {};
    // Cache mapping module name & settings to data directly used by module
    const moduleDataCache = {};

    const routes: Route[] = [];
    for (let i = 0; i < allStories.length; i += 1) {
        const story = allStories[i];
        routes.push({
            url: `/${story.full_slug}`,
            lastmod: story.published_at,
            full_slug: story.full_slug,
            noIndex: determineIfStoryIsNoIndex(story),
            payload: {
                storiesByFullSlug,
                seoData: seoDataPayload,
                globalArticleCopy: globalArticleCopyPayload,
                // eslint-disable-next-line no-await-in-loop
                apiData: await findDataModulesInStory(story, requestCache, moduleDataCache),
            },
        });
        if (i % PER_PAGE === 0) {
            console.log(`[REQUEST ${i / PER_PAGE + 1}]: Found ${Math.min(PER_PAGE, allStories.length - i)} stories`);
        }
        console.log(`[ROUTE FOUND]: /${story.full_slug}`);
    }

    console.log('[TOTAL REQUESTS]:', Math.ceil(allStories.length / PER_PAGE));
    console.log('[TOTAL ROUTES]:', routes.length);
    return routes;
};
