
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { ArticlePage001, LocalArticlePage001 } from '~/types/articlePage';
import type { GlobalArticleCopy } from '~/types/content';
import { postForm } from '@/utils/hubspot';

interface ArticleRatingData {
    id: string;
    data: { [key: string]: number };
}

const STORAGE_KEY = 'energysage-article-user-actions';

export default defineComponent({
    name: 'ArticleUserActions',
    props: {
        article: {
            type: Object as PropType<ArticlePage001 | LocalArticlePage001>,
            required: true,
        },
        seoData: {
            type: Object,
            required: true,
        },
        globalArticleCopy: {
            type: Object as PropType<GlobalArticleCopy>,
            required: true,
        },
    },
    data() {
        return {
            vote: 0,
            visitId: '',
            articleVotes: {} as { [key: string]: number },
        };
    },
    computed: {
        articleTitle() {
            if (this.article?.seo?.title) {
                return this.article.seo.title;
            }
            if (this.article?.component === 'ArticlePage001') {
                return this.article.articleEeat?.[0]?.header || '';
            }
            if (this.article?.component === 'LocalArticlePage001') {
                return this.article.eeat?.[0]?.heading || '';
            }
            return '';
        },
        articleCategory() {
            if (this.article?.component === 'ArticlePage001') {
                return this.article?.articleEeat?.[0]?.category;
            }
            return undefined;
        },
    },
    mounted() {
        this.updateFromLocalStorage();
    },
    methods: {
        updateFromLocalStorage() {
            // Read the data
            const data = localStorage.getItem(STORAGE_KEY);
            if (data?.length) {
                let parsedData: ArticleRatingData;
                try {
                    parsedData = JSON.parse(data);
                } catch {
                    // eslint-disable-next-line no-console
                    console.error(`Could not parse value: ${data}`);
                    return;
                }
                // Get previous `visitId`, or regenerate if not found
                // If this fails, user will look like a new user to Hubspot
                this.visitId = parsedData.id || this.generateVisitId();
                // Get previous `articleVotes`, or regenerate if not found
                // If this fails, only consequence is previous vote will not diplay
                this.articleVotes = parsedData.data || ({} as { [key: string]: number });

                // If `vote` is set for the current article, set it for the component
                // eslint-disable-next-line no-underscore-dangle
                const storedVote = this.articleVotes[`${this.article._uid}`];
                if ([1, 2].includes(storedVote)) {
                    this.vote = storedVote;
                }
            }
        },
        writeToLocalStorage() {
            if (this.vote === 0) {
                // Delete vote from data
                // eslint-disable-next-line no-underscore-dangle
                delete this.articleVotes[`${this.article._uid}`];
            } else if (this.vote) {
                // Update `articleVotes` with the current data
                // eslint-disable-next-line no-underscore-dangle
                this.articleVotes[`${this.article._uid}`] = this.vote;
            }
            // If visitId doesn't exist, create it
            this.visitId = this.visitId || this.generateVisitId();

            // Write the data with `visitId` and `articleVotes`
            const data = JSON.stringify({ id: this.visitId, data: this.articleVotes });
            localStorage.setItem(STORAGE_KEY, data);
        },
        generateVisitId(): string {
            /* Generate a random string to identify a visit
             * This is to identify the source of form submissions in hubspot.
             * It will only work to the extent that if a user changes their vote on an article
             * while they still have this data stored, it will be theoretically possible to
             * deduplicate the data sent to Hubspot.
             *
             * If they clear the data or modify it to the point that it can't be reloaded by
             * `updateFromLocalStorage`, they will get a fresh visitId and Hubspot may think that
             * duplicate votes from them are actually from new, different user.
             */

            const length = 16;
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const randomIndices = Array.from({ length }, () => Math.floor(Math.random() * characters.length));
            return randomIndices.map((index) => characters[index]).join('');
        },
        async submitHubspotForm(event: any) {
            // Store vote in component & local storage
            event.preventDefault();
            const value = Number(event.submitter.value);
            if (value === this.vote) {
                // Unset vote
                this.vote = 0;
            } else {
                this.vote = value;
            }
            this.writeToLocalStorage();

            // Create Hubspot payload
            const voteText = {
                0: 'none',
                1: 'helpful',
                2: 'not helpful',
            }[this.vote];
            // 'Article pages helpful/not helpful'
            const formID = '62f05f81-567e-4b34-a9cb-8da7fb686972';
            const fields = [
                { objectTypeId: '2-18509001', name: 'submitter_id', value: this.visitId },
                { objectTypeId: '2-18509001', name: 'id_label', value: 'visit ID' },
                { objectTypeId: '2-18509001', name: 'rating_label', value: voteText },
                { objectTypeId: '2-18509001', name: 'rating_code', value: this.vote },
                { objectTypeId: '2-18509001', name: 'max_rating', value: 2 },
                { objectTypeId: '2-18509001', name: 'subject_type', value: 'Article' },
                // eslint-disable-next-line no-underscore-dangle
                { objectTypeId: '2-18509001', name: 'subject_id', value: this.article._uid },
                { objectTypeId: '2-18509001', name: 'subject_label', value: this.articleTitle },
                { objectTypeId: '2-18509001', name: 'subject_url', value: window.location.pathname },
            ];

            if (this.$config.DEPLOY_ENV === 'prod') {
                // Post form data to Hubspot
                try {
                    await postForm(formID, fields);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            }
        },
    },
});
