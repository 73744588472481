/* eslint-disable no-param-reassign */

export const findDataModulesInStoryFunc = (supportedDataModules: Record<string, Function> = {}) => {
    const findDataModulesInStory = async (
        story: any,
        requestCache: Record<string, any> = {},
        moduleDataCache: Record<string, any> = {},
        moduleNameToData: Record<string, any> = {},
    ) => {
        // cannot use Promise.all() here, as the caches never get filled, so repeated requests are made
        const storyValues = Object.values(story);
        for (let i = 0; i < storyValues.length; i += 1) {
            const value: any = storyValues[i];
            if (typeof value === 'object' && !!value) {
                if (value.component in supportedDataModules) {
                    moduleDataCache[value.component] = moduleDataCache[value.component] || {};
                    moduleNameToData[value.component] = moduleNameToData[value.component] || {};

                    // eslint-disable-next-line no-await-in-loop
                    await supportedDataModules[value.component](
                        value,
                        requestCache,
                        moduleDataCache[value.component],
                        moduleNameToData[value.component],
                    );
                }

                // eslint-disable-next-line no-await-in-loop
                await findDataModulesInStory(value, requestCache, moduleDataCache, moduleNameToData);
            }
        }
        return moduleNameToData;
    };
    return findDataModulesInStory;
};
