import { render, staticRenderFns } from "./ArticlePage001.vue?vue&type=template&id=74a61754&scoped=true"
import script from "./ArticlePage001.vue?vue&type=script&lang=ts"
export * from "./ArticlePage001.vue?vue&type=script&lang=ts"
import style0 from "./ArticlePage001.vue?vue&type=style&index=0&id=74a61754&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a61754",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArticleBreadcrumbList: require('/home/runner/work/es-cms-articles/es-cms-articles/components/storyblok/ArticleBreadcrumbList.vue').default,Collapse: require('/home/runner/work/es-cms-articles/es-cms-articles/components/Collapse.vue').default,ArticleUserActions: require('/home/runner/work/es-cms-articles/es-cms-articles/components/ArticleUserActions.vue').default})
