import axios from 'axios';

const HUBSPOT_PORTAL_ID = 479905;

interface Field {
    name: string;
    value: any;
}

export async function postForm(formID: string, fields: Field[]) {
    if (!formID || !fields) {
        return Promise.reject(new Error('Missing required parameters'));
    }
    return axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${formID}`, {
        fields,
    });
}

export default { postForm };
