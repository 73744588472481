
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { ArticlePage001, BreadcrumbLink } from '~/types/articlePage';
import type { GlobalArticleCopy, SeoData } from '@/types/content';
import type { StoryblokComponentInterface } from '~/types/storyblok';
import Collapse from '~/components/Collapse.vue';
// @ts-ignore
import speedkitHydrate from '~/node_modules/nuxt-speedkit/runtime/hydrate.mjs';

export default defineComponent({
    name: 'ArticlePageComponent',
    components: {
        ArticleUserActions: speedkitHydrate(() => import('~/components/ArticleUserActions.vue')),
        Collapse,
    },
    props: {
        blok: {
            type: Object as PropType<ArticlePage001>,
            required: true,
        },
        breadcrumbLinks: {
            type: Array as PropType<BreadcrumbLink[]>,
            required: true,
        },
        firstPublishedAt: {
            type: String,
            default: '',
        },
        publishedAt: {
            type: String,
            default: '',
        },
        seoData: {
            type: Object as PropType<SeoData>,
            required: true,
        },
        globalArticleCopy: {
            type: Object as PropType<GlobalArticleCopy>,
            required: true,
        },
        h2List: {
            type: Array<StoryblokComponentInterface>,
            required: true,
        },
        apiData: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    data() {
        return {
            stickyActive: false,
        };
    },
    mounted() {
        const { stickySidebar } = this.$refs;
        const stickyObserver = new IntersectionObserver(
            ([entry]) => {
                this.stickyActive = !entry.isIntersecting;
            },
            {
                /* account for the height of the sticky nav */
                rootMargin: '-60px 0px 0px 0px',
                threshold: [1],
            },
        );
        stickyObserver.observe(stickySidebar as Element);
    },
});
